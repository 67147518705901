import React, { useState, useEffect, useRef, useCallback } from "react"
import { graphql, Link, navigate } from "gatsby"
import toast from "react-hot-toast"
import SimpleReactValidator from "simple-react-validator"
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
 
import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import NewsLetter from "../components/addon/newsLetter"
import CardBadge from "../components/addon/cardBadge"
import ShareButton from "../components/addon/shareButton"
import BlogsListingSection from "../components/pageSections/blogsListing"
import VideoModal from "../components/addon/videoModal"
import BlogCard from "../components/addon/blogCard"
import Loader from "../components/addon/Loader"
import CustomLink from '../components/addon/Link'

import API from "../api/blogs"
import useDeviceDetect from "../service/useDeviceDetect"
import useScript from "../service/useScript"
import { getBannerVideoThumbnail } from "../service/helper"



const BlogDetailPage = props => {
const { data } = props
let pageInfo, metaTag;

useScript("/assets/js/bootstrap4-rating-input.js")
useScript("/assets/js/star_rating.js")
const {
isMobile
} = useDeviceDetect()
  const { executeRecaptcha } = useGoogleReCaptcha();
const [simpleValidator] = useState(useRef(new SimpleReactValidator()))
const [, forceUpdate] = useState();
const defaultImageSrc =
isMobile === true ?
"https://via.placeholder.com/330x222" :
"https://via.placeholder.com/730x492"
const [mostViewedParams] = useState({
name: "most_viewed_articles",
})
const [topRatedParams] = useState({
name: "top_rated_articles",
})
let [queryForm, setQueryForm] = useState({
  email: "",
  your_query: "",
  webform_id : "blog_query"
})
const [videoURL, setVideoURL] = useState("")
const [showModal, setShowModal] = useState(false)

let [topRatedArticles, setTopRatedArticles] = useState([])
let [topRatedArticlesCount, setTopRatedArticlesCount] = useState(0)
let visibleCardsCount = 2
let [loading, setLoading] = useState(false)
let [showNoDataText, setShowNoDataText] = useState(false)
const [metaImage, setMetaImage] = useState("")

let imageSrc = ""
let altText = ""
let info = {}
info = data?.allNodeArticle?.nodes[0]
const tags = info?.metatag ? info.metatag : []
imageSrc =
isMobile === true ?
info?.relationships?.image_mobile?.uri?.url :
info?.relationships?.image?.uri?.url
altText = info?.field_image?.alt || "Hinduja Hospital"

if (data && data.allNodeArticle && data.allNodeArticle.nodes) {
  pageInfo = info
  metaTag = tags
}

useEffect(() => {
  getTopRatedArticles()
}, [])
const getTopRatedArticles = () => {
setLoading(true)
API.getArticles(topRatedParams)
.then(response => {
if (response && response.length > 0) {
setTopRatedArticles(response)
setTopRatedArticlesCount(response.length)
setShowNoDataText(false)
} else {
setShowNoDataText(true)
}
setLoading(false)
})
.catch(err => {
setLoading(false)
console.log(err)
// addToast("Something went wrong", { appearance: "error" })
})
}
const openVideoModal = url => {
setVideoURL(url)
setShowModal(true)
}
const closeModal = () => {
setShowModal(false)
}
const onQueryDataChange = event => {
setQueryForm({
...queryForm,
[event.target.id]: event.target.value,
})
}
const onQuerySubmit = async () => {
  const allValid = simpleValidator.current.allValid()
  if (allValid === true) {
      console.log('executeRecaptcha', executeRecaptcha)
      // Check if the captcha was skipped or not
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
      }
      // This is the same as grecaptcha.execute on traditional html script tags
      const token = await executeRecaptcha('homepage')
      console.log('Recaptcha token ', token)
      // Prepare the data for the server, specifically body-parser

      // POST request to your server
      
      
      simpleValidator.current.hideMessages()
      API.postBlogQuery(queryForm)
      .then(response => {
        toast.success("Form submitted successfully")
        navigate("/blog-query-thankyou")
    })
    .catch(err => {
      console.log(err)
      toast.error("Something went wrong")
    })
  } else {
    simpleValidator.current.showMessages()
    forceUpdate(1)
  }
}

const getVideoImage = () => {
const url = getBannerVideoThumbnail(pageInfo.field_video.input)
if (url) {
return url
}
return null
}
const playVideo = () => {
const url = data.allNodeArticle.nodes[0].field_video.input
setShowModal(true)
if (url) {
setVideoURL(url)
}
}
return (
<Layout>
    <Meta
    files={{
    js: [],
    css: ["/assets/css/blog.css"],
    }}
    tags={metaTag}
    imgSrc = {metaImage}
    />
    <main className="innerpage">
        {pageInfo?.relationships?.field_breadcrumb_blogs &&
            <section className="breadcrumbs">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <ul>
                                {pageInfo?.relationships?.field_breadcrumb_blogs?.field_breadcrumb_link?.map((el, index) => {
                                            return  (
                                            <li key={index}>
                                                <CustomLink data={{link : el}}>{el.title}</CustomLink>
                                            </li>
                                        )
                                    })
                                }
                                <li><span>{pageInfo?.relationships?.field_breadcrumb_blogs?.field_title}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        }
        <section className="section-bg section-py">
            <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        <div className="blog_detail_head">
                            <div className="badge_wrap">
                                {pageInfo?.relationships?.tags &&
                                pageInfo.relationships.tags.length > 0 && (
                                <CardBadge
                                    data={pageInfo.relationships.tags[0]?.name}
                                    />
                                )}
                                {pageInfo?.path?.alias && (
                                    <ShareButton url={pageInfo.path.alias} title={pageInfo.title ? pageInfo.title : "Hinduja Hospital"} image={imageSrc} setMetaImage={setMetaImage}/>
                                )}
                            </div>
                            <div className="heading_with_likes">
                                <h1>{pageInfo?.title || ""}</h1>
                                <div className="blog_by">
                                    <span className="name">By {pageInfo?.author || ""}</span>
                                    <span className="date_time">
                                    {pageInfo?.created || ""}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div
                        className="blog_detail_image"
                        style={{ position: "relative" }}
                        >
                        {pageInfo?.field_video?.input ? (
                        <>
                        <img
                        id="eventBannerVideoThumbnail"
                        src={getVideoImage() || defaultImageSrc}
                        alt={pageInfo.title || ""}
                        className="vid_img "
                        />
                        <button
                        className="video-popup"
                        data-toggle="modal"
                        data-target="#modal_video"
                        data-src="yXG2BeAYVGs"
                        onClick={playVideo}
                        style={{
                        position: "absolute",
                        right: "50%",
                        top: "50%",
                        transform: "translate(-50,-70)",
                        border: "none",
                        outline: "none",
                        background: "none",
                        }}
                        >
                        <img
                            src="/assets/images/icons/video_icon_white.svg"
                            alt="white-play-icon"
                            />
                        </button>
                        </>
                        ) : (
                        <figure>
                            {imageSrc ? (
                            <img src={imageSrc} alt={altText} />
                            ) : (
                            <img src={defaultImageSrc} alt={altText} />
                            )}
                        </figure>
                        )}
                    </div>
                    <div className="blog_content">
                        {pageInfo.header && (
                        <div className="blog_header">{pageInfo.header}</div>
                        )}
                        {pageInfo?.content?.processed && (
                        <div
                        className="blog_body"
                        dangerouslySetInnerHTML={{
                        __html: pageInfo.content.processed,
                        }}
                        >
                    </div>
                    )}
                    {pageInfo.footer && (
                    <div className="blog_footer">{pageInfo.footer}</div>
                    )}
                </div>
                <div className="comment_section">
                    <div className="comment_header">
                        <h2>Submit your query</h2>
                    </div>
                    <div className="comment_box form_wrap">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group -animated">
                                    <input
                                        onChange={onQueryDataChange}
                                        value={queryForm.email}
                                        id="email"
                                        className="form-control"
                                        type="text"
                                        name="email"
                                        placeholder="Email Id"
                                        />
                                    <span className="err_msg mandatory">
                                    {simpleValidator.current.message('email', queryForm.email, 'required|email')}
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group -animated">
                                    <textarea
                                        onChange={onQueryDataChange}
                                        value={queryForm.your_query}
                                        id="your_query"
                                        className="form-control"
                                        name="your_query"
                                        placeholder="Your query"
                                        ></textarea>
                                    <span className="err_msg mandatory">
                                    {simpleValidator.current.message('your_query', queryForm.your_query, 'required')}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="form_block pl-0">
                            {/* <button className="btn btn-primary mr-3" onClick={handleReCaptchaVerify}>Verify recaptcha</button> */}
                            <button
                                onClick={onQuerySubmit}
                                className="btn btn-primary"
                                >
                            Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                {topRatedArticles && topRatedArticles.length > 0 && (
                <div className="blog_right_col">
                    <h2>Top Rated Articles</h2>
                    {topRatedArticles.map((el, index) => {
                    return (
                    <BlogCard
                        data={el}
                        key={index}
                        openVideoModal={openVideoModal}
                        setMetaImage={setMetaImage}
                        />
                    )
                    })}
                    {topRatedArticlesCount > visibleCardsCount && !loading && (
                    <Link to={"/"} className="btn btn-primary">
                    View All
                    </Link>
                    )}
                </div>
                )}
                {loading === true && 
                <Loader loadingMessage="Loading..." />
                }
                {showNoDataText === true && !loading && (
                <div className="load_more_wrap text-center">
                    <p>Currently there is no data</p>
                </div>
                )}
            </div>
            </div>
            </div>
        </section>
        <section className="section-py">
            <div className="container">
                <div className="row filter_row">
                    <div className="col-md-12">
                        <h2 className="section-heading">Most Viewed Articles</h2>
                    </div>
                </div>
            </div>
            <div className="container">
                <BlogsListingSection
                    additionalParams={mostViewedParams}
                    mostViewdArticles={true}
                    key="mostViewedArticles"
                    openVideoModal={openVideoModal}
                    setMetaImage={setMetaImage}
                    />
            </div>
        </section>
        <NewsLetter />
    </main>
    <VideoModal
        videoURL={videoURL}
        showModal={showModal}
        closeModal={closeModal}
        />
</Layout>
)
}
export const query = graphql`
query ($blogId: String!) {

    allNodeArticle(filter: {id: {eq: $blogId}}) {
  
      nodes {
  
        id
  
        path {
  
          alias
  
        }
        created(formatString: "MMMM D,YYYY | hh:mm A")
  
        author: field_author
  
        title
  
        field_image {
  
          alt
  
        }
  
        summary: body {
  
          summary
  
        }
  
        content: body {
  
          processed
  
        }
  
        relationships {
  
          image: field_image {
  
            id
  
            uri {
  
              value
  
              url
  
            }
  
          }
  
          image_mobile: field_image {
  
            id
  
            uri {
  
              value
  
              url
  
            }
  
          }
  
          field_breadcrumb_blogs {
  
            field_title
  
            field_breadcrumb_link {
  
              uri
  
              title
  
            }
  
          }
  
        }
  
      }
  
    }
  }
`

export default BlogDetailPage